import { KatapultDialog } from '../elements/katapult-elements/katapult-dialog.js';

const TOKEN_PREFIX_DELIMITER = '$(';
const TOKEN_SUFFIX_DELIMITER = ')';
const TOKEN_REGEX = /(?<=\$\()[_\-a-zA-Z0-9]+(?=\))/g;

/**
 * @typedef {(token: string) => string | Promise<string>} TokenResolver
 */

/**
 * Given a tokenized url, a set unique tokens will be returned.
 * @param {string} url
 * @returns {string[]}
 * @throws {TypeError} If url is not a string.
 */
function getTokensFromUrl(url) {
  if (typeof url !== 'string') throw new TypeError('url must be a string');
  const iterator = url.matchAll(TOKEN_REGEX);
  const allTokenInstances = Array.from(iterator, (match) => match[0]);
  const uniqueTokenInstances = [...new Set(allTokenInstances)];
  return uniqueTokenInstances;
}

/**
 * Given a tokenized url and a token resolver function, a url will be returned where all tokens are replaced with their resolved values.
 * @param {string} url
 * @param {TokenResolver} resolveToken - A function returns a value for a given token.
 * @returns {Promise<Object<string, string>>} - A promise which resolves to an object where the keys are tokens and the values are the resolved values.
 */
async function getTokenValuesFromUrl(url, resolveToken) {
  const tokenValues = /** @type { Object<string, string> } */ ({});

  const tokens = getTokensFromUrl(url);
  for (const token of tokens) {
    const value = await resolveToken(token);
    tokenValues[token] = value;
  }

  return tokenValues;
}

/**
 * Given a tokenized url and an array of resolved tokens, a url will be returned where all tokens are replaced with their resolved values.
 * @param {string} url
 * @param {Object<string, string>} tokenValues - An object where the keys are tokens and the values are the resolved values.
 * @returns {string} - The url in which all tokens have been replaced with their resolved values.
 * @throws {TypeError} If url is not a string.
 */
function replaceUrlTokens(url, tokenValues) {
  if (typeof url !== 'string') throw new TypeError('url must be a string');
  for (const [token, value] of Object.entries(tokenValues)) {
    const enclosedToken = `${TOKEN_PREFIX_DELIMITER}${token}${TOKEN_SUFFIX_DELIMITER}`;
    url = url.replaceAll(enclosedToken, encodeURIComponent(value));
  }
  return url;
}

/**
 * Given a tokenized url and a token resolver function, a url will be returned where all tokens are replaced with their resolved values.
 * @param {string} url
 * @param {TokenResolver} resolveToken - A function returns a value for a given token.
 * @returns {Promise<string>} - The url in which all tokens have been replaced with their resolved values.
 */
async function resolveUrl(url, resolveToken) {
  const tokenValues = await getTokenValuesFromUrl(url, resolveToken);
  return replaceUrlTokens(url, tokenValues);
}

/**
 * Prompts the user to confirm that they want to run a tool unless they have previously confirmed that they wish to run the tool.
 * @param {string} toolId
 * @returns {Promise<boolean>}
 * @throws {TypeError} If toolId is not a string.
 * @throws {Error} If toolId is an empty string.
 */
async function confirmMayRunTool(toolId) {
  if (typeof toolId !== 'string') throw new TypeError('toolId must be a string');
  if (toolId.length === 0) throw new Error('toolId must not be an empty string');

  const { uid } = globalThis.firebase.auth().currentUser ?? {};

  const toolLocalStoragePath = ['may_run_api_tool_from_model_without_confirmation', uid, toolId].filter((x) => !!x).join('_');

  const mayRunToolWithoutConfirmation = localStorage.getItem(toolLocalStoragePath) === 'true';
  if (mayRunToolWithoutConfirmation) return true;

  const shouldRunTool = await KatapultDialog.confirm({
    text: `This tool will send data to an external source outside of this application. Be sure that you trust the creator of this tool.`,
    confirmButton: { label: `Continue` }
  }).confirmed;

  if (shouldRunTool) {
    localStorage.setItem(toolLocalStoragePath, 'true');
    return true;
  }

  return false;
}

/**
 * @typedef {Object} WebToolModel
 * @property {string} [id] - The id of the tool.
 * @property {string} label - The name of the tool.
 * @property {string} endpoint_url - The url of the tool.
 * @property {string} endpoint_action - Whether the tool should be opened in a new tab or not.
 */

/**
 * @param {WebToolModel} webToolModel
 * @param {TokenResolver} resolveToken
 * @returns {Promise<void>}
 */
async function runWebTool(webToolModel, resolveToken) {
  const { label = 'Web Tool', id = label, endpoint_url: endpointUrl, endpoint_action: endpointAction } = webToolModel;
  if (!endpointUrl) throw new Error('endpoint_url is missing from the web tool model');
  if (!endpointAction) throw new Error('endpoint_action is missing from the web tool model');

  const mayRunTool = await confirmMayRunTool(id);
  if (!mayRunTool) return;

  const resolvedUrl = await resolveUrl(endpointUrl, resolveToken);

  switch (endpointAction) {
    case 'API_CALL':
      await fetch(resolvedUrl, { method: 'POST' })
        .then(() => {
          KatapultDialog.alert({ text: `${label} was run successfully`, dialog: { title: `${label} Succeeded`, icon: 'check_circle' } });
        })
        .catch((err) => {
          KatapultDialog.alert({
            text: `An error occurred while running ${label}:\n\n${err.message}`,
            dialog: { title: `${label} Failed`, icon: 'error', color: 'var(--sl-color-danger)' }
          });
        });
      break;
    case 'OPEN_LINK_IN_NEW_TAB':
      openWindow(resolvedUrl);
      break;
    default:
      throw new Error(`Unknown endpoint_action: ${endpointAction}`);
  }
}

/**
 * Opens the url in a new tab
 * @param {string} url
 */
function openWindow(url) {
  window.open(url, '_blank');
}

export { getTokensFromUrl, runWebTool };
